<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("connectors.intranet.intranet") }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="!editing"
            :to="{
              name: 'r_connectors-intranet-add'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfIntranets && listOfIntranets.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="intranet in listOfIntranets"
        :key="intranet.id"
      >
        <router-link
          :to="{
            name: 'r_connectors-intranet-edit',
            params: { intranetId: intranet.id }
          }"
          class="clebex-item-content-wrapper"
          v-if="!editing"
        >
          <span class="label">
            <span class="text">
              <span class="highlight">{{ intranet.name }}</span>
            </span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </router-link>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`intranet${intranet.id}`"
              name="user"
              :selected="selectedIntranets.includes(intranet.id)"
              :value="intranet.id"
              @change="selectIntranet(intranet)"
            />
            <label :for="`intranet${intranet.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              {{ intranet.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="this.editing = !this.editing"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedIntranets.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedIntranets.length +
                "/" +
                listOfIntranets.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("connectors.intranet.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("connectors.intranet.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Intranets",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      show: false,
      processing: false,
      editing: false,
      selectedIntranets: []
    };
  },
  created() {
    this.getIntranets();
  },
  computed: {
    ...mapState("intranet", ["intranets", "selectedIntranet"]),
    listOfIntranets() {
      const query = this.searchQuery;
      if (this.intranets && this.intranets.length) {
        if (query && query.length > 1) {
          return this.intranets.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.intranets;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("intranet", [
      "getIntranets",
      "deleteIntranet",
      "setSelectedIntranet"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    selectIntranet(intranet) {
      if (this.selectedIntranets.includes(intranet.id)) {
        this.selectedIntranets.splice(
          this.selectedIntranets.indexOf(intranet.id),
          1
        );
      } else {
        this.selectedIntranets.push(intranet.id);
      }
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedIntranets.length; i++) {
        await this.deleteIntranet(this.selectedIntranets[i]);
      }

      this.setPaginationData(null);
      this.processing = false;
      this.setSelectedIntranet(null);
      this.editing = false;
      this.selectedIntranets = [];
      this.getIntranets();
      this.$nextTick(() => {
        this.$router.push({ name: this.backLinkName });
      });
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
